import React from "react";
import web from "../src/images/shiva.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <span>
            <img
              src={web}
              height={10}
              width={80}
              fit="center"
              className="img-fluid"
            />
             {""} Mukkoti
          </span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                activeClassName="menu_active"
                exact
                className="nav-link active"
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/"
                role="button"
                id="navbarDropdown"
                data-toggle="dropdown"
                data-hover="dropdown"
              >
                Gallery <span className="caret"></span>
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/gallery" className="dropdown-item">
                   Images
                  </Link>
                  <Link to="/videos" className="dropdown-item">
                   Videos
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                activeClassName="menu_active"
                className="nav-link"
                to="/about"
              >
                About
              </Link>
            </li>

          

            
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/"
                role="button"
                id="navbarDropdown"
                data-toggle="dropdown"
                data-hover="dropdown"
              >
                Sevas <span className="caret"></span>
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/sevas" className="dropdown-item">
                   Temple Timings
                  </Link>
                  <Link to="/weekelysevas" className="dropdown-item">
                   Weekly Sevas
                  </Link>
                  <Link to="/monthlysevas" className="dropdown-item">
                   Monthly Sevas
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/"
                role="button"
                id="navbarDropdown"
                data-toggle="dropdown"
                data-hover="dropdown"
              >
                Schemes <span className="caret"></span>
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/wooden" className="dropdown-item">
                    Wooden Chariot
                  </Link>
                  <Link to="/annadanam" className="dropdown-item">
                   Sri Dakshinamorthi Annadanam Trust
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                activeClassName="menu_active"
                className="nav-link"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
