import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="navbar-static-bottom">
        <center><p>© 2020 SSSPL. All Rights Reserved | Terms and Conditions</p></center>
      </footer>
    </>
  );
};

export default Footer;
