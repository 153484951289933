import React from "react";

const Sevas = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 jumbotron">
          <h3 className="text-center">Sevas</h3>
        </div>
      </div>
      <div className="card md-4">
        <table class="table table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Seva Name</th>
              <th scope="col">Timings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Temple Opening</td>
              <td>6 A.M</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td> Abhishekam to Parivar Gods</td>
              <td>6.00 AM to 7.00 AM</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Sarva Darshan</td>
              <td>7.00 A.M</td>
            </tr>

            <tr>
              <th scope="row">4</th>
              <td>Abhishekam to Swamy Varu and Amma Varu</td>
              <td>7.00 A.M to 9.00 A.M</td>
            </tr>

            <tr>
              <th scope="row">5</th>
              <td>Naivedyam</td>
              <td>11.30 A.M</td>
            </tr>

            <tr>
              <th scope="row">6</th>
              <td>Sarva Darshan</td>
              <td>9.00 AM to 7.30 PM</td>
            </tr>

            <tr>
              <th scope="row">7</th>
              <td>Deeparadhana , Naivedyam</td>
              <td>4.30 PM </td>
            </tr>

            <tr>
              <th scope="row">8</th>
              <td>Naivedyam, Ekantha Seva</td>
              <td>7.30 PM</td>
            </tr>

            <tr>
              <th scope="row">9</th>
              <td>Temple Closing</td>
              <td>8.00 PM</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sevas;
