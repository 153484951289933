import React from "react";
import YouTube from '@u-wave/react-youtube';
let videoIdList=["nFgF9WA468s","tqyq_i-aYAk","6SJmAWHZxFw"];


class Videos extends React.Component {

  constructor(props){
    super();
    this.state = {};
    this.i = 0;
   
  }

  componentDidMount() {
    this.setState({videoId: videoIdList[this.i]});
 }


    
    render() {
        const opts = {
           
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 1
            },
            // style={{height:"100%",width:"1000"}}
          
        };
        

        return (
            <YouTube
                video={this.state.videoId}
                opts={opts}
                onReady={this._onReady}
                onEnd={this._onEnd}
                allowFullscreen="true"
                style={{height:"1000px",width:"100%",alignContent:"center",alignSelf:"center"}}
                autoplay="false"
                
                
            />
        );
    }
    _onEnd = () => {
        this.setState({videoId: videoIdList[++this.i]});
    }

   

}

export default Videos;
