import React from "react";
import web from "../src/images/hero-img.png";
import { NavLink } from "react-router-dom";
import Commom from "./Commom";

const About = () => {
  return (
    <div className="container">
      {/* <Commom
        name=""
        imgsrc={web}
        visit="/contact"
        btname="Contact Now"
      /> */}

      <div className="card md-4">
        <div
          class="card bg-primary text-white"
          style={{ padding: 10, color: "blue" }}
        >
          About Mukkoti Agastheeswara Swami Temple
        </div>
        <div class="card" style={{ padding: 20, margin: 10 }}>
          <blockquote class="blockquote mb-0">
            <ul>
              <li>
                <p>
                  Mukkoti Agastheeswara Swami Temple is just 12 km from
                  Tirupathi railway Station. Lord Siva is known as Agastheswara
                  Swamy and the main deity in this temple. The sacred shrine is
                  famous for the god Shiva in linga rupam, which was installed
                  by the great saint Agasthya, the son of Urvashi.
                </p>
              </li>

              <li>
                <p>
                  Tondavada is the neighboring village, where visitors have
                  facilities to rest. Pilgrims can use the tank provided outside
                  the temple for their needs. With 3 entrances and a large
                  compound wall, the Dwarapalakas at the entrance have been
                  beautifully carved. They add grandeur to the temple. The
                  second prakara inside includes a separate shrine for Goddess
                  Parvati called by the name of vallimata.
                </p>
              </li>

              <li>
                <p>
                  Right in front of the temple and in the middle of the river
                  there is a beautiful Mandapam. In this you can see the
                  attractive statues of Sri Balaji, Ayyappa Swamy, Lord
                  Ganapathi and a foot print of Lord Sri Venkateswara Swami.
                </p>
              </li>
            </ul>
          </blockquote>
        </div>

        <div
          class="card bg-primary text-white"
          style={{ padding: 10, color: "blue" }}
        >
          Mukkoti Sri Agastheshwara Swamy Temple History
        </div>
        <div class="card" style={{ padding: 20, margin: 10 }}>
          <blockquote class="blockquote mb-0">
            <ul>
              <li>
                <p>
                  According to Legends, after marriage Lord Srinivasa, the sage
                  Agasthya asks the lord to stay in his ashram. He performed
                  nityapoojas to Lord Shiva in this place, which is nothing more
                  than the Agasthyeswara swami temple. The wise Agasthya here
                  made a penace for Lord Shiva and caused the origin of the
                  “Suwarnamukhi” river from this place.
                </p>
              </li>
            </ul>
          </blockquote>
        </div>

        <div
          class="card bg-primary text-white"
          style={{ padding: 10, color: "blue" }}
        >
          Temple opening time
        </div>
        <div class="card" style={{ padding: 20, margin: 10 }}>
          <blockquote class="blockquote mb-0">
            <p>
              The temple remains open from 6.30 AM to 1.30 PM and 3.30 PM to
              7.30 PM
            </p>
          </blockquote>
        </div>

        <div
          class="card bg-primary text-white"
          style={{ padding: 10, color: "blue" }}
        >
          Connectivity to temple
        </div>
        <div class="card" style={{ padding: 20, margin: 10 }}>
          <blockquote class="blockquote mb-0">
            <ul>
              <li>
                The local A.P.S.R.T.C Buses are available to Chandragiri from
                Tirupathi Busstand and Railway Station
              </li>
              <li>
                The temple is located at 850 meters from Thondavada Bus Stop, 3
                Kms from chandragiri Busstation
              </li>
              <li>
                Buses From KSRTC, TNSTC and SETC also operate their services
              </li>
              <li>
                There is also Private Transport which operates in the city
              </li>
            </ul>
          </blockquote>
        </div>

        <div
          class="card bg-primary text-white"
          style={{ padding: 10, color: "blue" }}
        >
          Location
        </div>
        <div class="card" style={{ padding: 20, margin: 10 }}>
          <blockquote class="blockquote mb-0">
            <p>Mukkoti Temple,Thondava</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.99824190588!2d79.33875281431014!3d13.59691950452831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb2b483e2834509%3A0x3e38e7743f010de5!2sMukkoti%20Agastheeswaralayam%2C%20Tirupati%20Rd%2C%20Thondavada%2C%20Andhra%20Pradesh%20517505!5e0!3m2!1sen!2sin!4v1600676672247!5m2!1sen!2sin"
              width="100%"
              height="350px"
            ></iframe>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default About;
