import React from "react";
import web from "../src/images/i11.jpg";
import { NavLink } from "react-router-dom";
import Commom from "./Commom";

const Home = () => {
  return (
    <Commom
      name="Thondavada Agastheeshwara Temple"
      imgsrc={web}
      visit="/about"
      btname="Know More"
    />
  );
};

export default Home;
