import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./Home";
import Service from "./Service";
import About from "./About";
import Contact from "./Contact";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Switch, Route, Redirect } from "react-router-dom";
import Sevas from "./Sevas";
import {Wooden} from "./Wooden";
import { Annadanam } from "./Annadanam";
import Weekelysevas from "./Weekelysevas";
import Monthlysevas from "./Monthlysevas";
import Videos from "./Videos";


const App = () => {
  return (
    <>
      <Navbar />
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/gallery" component={Service} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/sevas" component={Sevas} />
        <Route exact path="/wooden" component={Wooden} />
        <Route exact path="/annadanam" component={Annadanam} />
        <Route exact path="/weekelysevas" component={Weekelysevas} />
        <Route exact path="/monthlysevas" component={Monthlysevas} />
        <Route exact path="/videos" component={Videos} />
       
       
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
