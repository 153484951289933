import React from "react";
import web from "../src/images/chariot.jpg";
import we from "../src/images/annadanam.jpg";

export class Wooden extends React.Component{

  state = { isOpen: false,isOpens:false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
    console.log("cliked");
  };
  handleShowDialog1 = () => {
      this.setState({ isOpens: !this.state.isOpens });
      console.log("cliked");
    };

render(){
  return(

      <div className="container" >
         
          <div className="row">
<div className="col-md-12 col-12 mx-auto">
      <h2 className="card-header text-center" style={{margin:"10px"}}>1. Wooden Chariot</h2> 
      <div className="card" style={{margin:"10px",border:"2px solid grey"}} >
        <img src={web} className="card-img-top" alt="proj1"  onClick={this.handleShowDialog}/>
        <div className="card-body">
          
          <h2 className="card-text">
            The historic Agastheeswara temple to get a wooden chariot soon
            to propagate its glorious history and architectural grandeur.
         </h2>
          
        </div>
      </div>


      {this.state.isOpen && (
        <dialog
          className="dialog"
          style={{ position: "relative" }}
          open
          onClick={this.handleShowDialog}
        >
          <img
            className="image"
            src={web}
            onClick={this.handleShowDialog}
            alt="no image"
          />
        </dialog>
      )}
    </div>
      </div>
      </div>
  );

}}
 