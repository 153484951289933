import web from "../src/images/s1.jpg";
import app from "../src/images/s2.PNG";
import android from "../src/images/s3.jpg";
import digital from "../src/images/digital.jpeg";
import marketing from "../src/images/marketing.jpg";
import software from "../src/images/software.jpeg";
import i1 from "../src/images/i1.jpg";
import i2 from "../src/images/i2.jpg";
import i3 from "../src/images/i3.jpg";

import i10 from "../src/images/i11.jpg";
import i11 from "../src/images/i12.jpg";


const Sdata = [


  {
    imgsrc: i1,
    title: "Image 1",
  },
  
  {
    imgsrc: i2,
    title: "Image 2",
  },

  {
    imgsrc: i3,
    title: "Image 3",
  },
  
  {
    imgsrc: i10,
    title: "Image 4",
  },
  {
    imgsrc: i11,
    title: "Image 5",
  },
  {
    imgsrc: web,
    title: "Image 7",
  },
  {
    imgsrc: app,
    title: "Image 8",
  },
  {
    imgsrc: software,
    title: "Image 9",
  },
  {
    imgsrc: digital,
    title: "Image 10",
  }
 
];

export default Sdata;
