import React from "react";

const Weekelysevas = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 jumbotron">
          <h3 className="text-center">Coming Soon...</h3>
        </div>
          </div>
    </div>
  );
};

export default Weekelysevas;
